import React from "react";
import {BrowserRouter,Route,Routes} from "react-router-dom";
import Bills from "./Bills"; 
import Login from "./Login"; 
import Home from "./Home"; 

export default function Navigation(){
    return(
        <>
        <BrowserRouter>
        <Routes>
            <Route element={<Login/>} path="/"></Route>
            <Route element={<Bills/>} path="/Bills"></Route> 
            <Route element={<Home/>} path="/Home"></Route> 
            
        </Routes>
        </BrowserRouter>
        </>
    )
}