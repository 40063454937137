import React, { useEffect } from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";
import Header from "./Header";
import axios from "axios";
import { useState } from "react";
import config from './config.json'

export default function App() {
  const navigate=useNavigate(); 
  const [array, setArray]=useState([]);
  useEffect(()=>{
    localStorage.setItem("selectedid",0);
  const url=config.url+"webbilllist"
  const data={};
  const header={};
  axios
  .post(url,data, header)
  .then((res)=>{
    console.log(res.data)
    setArray(res.data);
  })
  .catch((err)=>{
    console.log(err);
  });
},[]);

  return (
    <>
      <Header />
      <div className="page">
        <Menu />
        <div className="page_contents">
          <div className="page_mainheader">
            <label>Bills</label> 
          </div>
          <div className="page_contents_search">
            <div className="form_row_two">
              <div className="form_element">
                {/* <label>User</label>
                <input type="text" /> */}
              </div>
              <div className="form_element">
                {/* <label>UserType</label>
                <select>
                  <option>All</option>
                  <option>Conductor</option>
                  <option>User</option>
                </select> */}
              </div>
              {/* <button>Search</button> */}
            </div>
          </div>
          <div className="page_contents_list">
            <table>
              <tr>
                <th>ID</th>
                <th></th>
                <th></th> 
                <th>Edit</th>
              </tr>
              {
              array.map((itm, index)=>{
                  return <><tr>
                  <td>{itm.id}</td>
                  <td>{itm.vehiclenumber}</td>
                  <td>{itm.type}</td>
                  
                  <td>
                    {/* <button onClick={(e)=>handleClick(e,itm.id)}>View</button> */}
                  </td>
                </tr></>
                })
              }
              {/* <tr>
                <td>1</td>
                <td>Abc</td>
                <td>A01</td>
                <td>1</td>
                <td>
                  <button>Edit</button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Abc</td>
                <td>A01</td>
                <td>1</td>
                <td>
                  <button>Edit</button>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Abc</td>
                <td>A01</td>
                <td>1</td>
                <td>
                  <button>Edit</button>
                </td>
              </tr> */}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
