import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./Header";
import Menu from "./Menu";
import { useSelector } from "react-redux";
import config from "./config.json";
import axios from "axios";
import Loader from "./Loader";

export default function StudentEdit() {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [array, setArray] = useState([]);
  const [isloading, setLoading]=useState(false)

  const username = useSelector((state) => state.username);
  const userid = useSelector((state) => state.userid);
  const password = useSelector((state) => state.password);

  useEffect((_) => {
    const currentDate = new Date().toISOString().split("T")[0];
    setFrom(currentDate);
    setTo(currentDate);
    const url = config.url;
    const header = {};
    const data = {
      task: "getbills",
      fromdate: currentDate,
      todate: currentDate,
      username: username,
      password: password,
      userid: userid,
    };
    setLoading(true)
    axios
      .post(url, data, header)
      .then((res) => {
        setLoading(false)
        let resp = res.data.result;
        if (resp.length > 0) {
          setArray(resp);
        }
      })
      .catch((err) => {
        setLoading(false)
        //alert("Error in Username or Password!");
      });
  }, []);
  const handleFromChange = (event) => {
    setFrom(event.target.value);
  };
  const handleToChange = (event) => {
    setTo(event.target.value);
  };

  const handleClick=e=>{
    const url = config.url;
    const header = {};
    const data = {
      task: "getbills",
      fromdate: from,
      todate: to,
      username: username,
      password: password,
      userid: userid,
    };
    setLoading(true)
    axios
      .post(url, data, header)
      .then((res) => {
        setLoading(false)
        let resp = res.data.result;
        if (resp.length > 0) {
          setArray(resp);
        }
      })
      .catch((err) => {
        setLoading(false)
        //alert("Error in Username or Password!");
      });
  }
  return (
    <>
      <Header />
      <div className="page">
        <Menu />
        <div className="page_contents">
          <div className="page_contents_search">
            <h3></h3>{" "}
            <div className="form_row_two">
              <div className="form_element">
                <label style={{fontWeight:'bold'}}>From Date</label>
                <input type="date" value={from} onChange={handleFromChange} />
              </div>
              <div className="form_element">
                <label  style={{fontWeight:'bold'}}>To Date</label>
                <input type="date" value={to} onChange={handleToChange} />{" "}
              </div>
            </div>
            <div>
              {isloading?<Loader/>:<button onClick={e=>handleClick(e)}>OK</button>
              }
            </div>
          </div>
          <div className="page_contents_table">
            <table>
              <tr>
                <th>Total Sale</th>
                <th>Product Sale</th>
                <th>Card Sale</th>
                <th>Time Sale</th>
              </tr>
              {array.map((itm, indx) => {
                return (
                  <>
                    <tr>
                      <td>{itm.total!=null?parseFloat(itm.total).toFixed(2):0}</td>
                      <td>{itm.product!=null?parseFloat(itm.product).toFixed(2):0}</td>
                      <td>{itm.card!=null?parseFloat(itm.card).toFixed(2):0}</td>
                      <td>{itm.time!=null?parseFloat(itm.time).toFixed(2):0}</td> 
                    </tr>
                  </>
                ) 
              })}
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
