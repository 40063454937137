import React from "react";
import { useSelector } from "react-redux";
import logo from './images/Blue.png'
export default function Header() {
  const username=useSelector(state=>state.username)
  return (
    <div className="headerclass"> 
      <img src={logo}/> 
      <label>{username}</label>
    </div>
  );
}
