import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "./config.json";
import { useDispatch, useSelector } from "react-redux";

export default function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const userid = useSelector((state) => state.userid);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userid != "") {
      navigate("/Home");
    }
  }, []);
  const handleClick = (e) => {
    // if (username == "admin" && password == "admin") navigate("/Home");
    // else alert("Error in Username or Password!");
    const url = config.url;
    const header = {};
    const data = { task: "weblogin", username: username, password: password };
    axios
      .post(url, data, header)
      .then((res) => {
        let resp = res.data.result;
        if (resp.length > 0) {
          dispatch({ type: "setUserid", payload: resp[0].id });
          dispatch({ type: "setUsername", payload: username });
          dispatch({ type: "setPassword", payload: password });
          navigate("/Home");
        }
      })
      .catch((err) => {
        alert("Error in Username or Password!");
      });
  };
  return (
    <div className="loginpage">
      <div className="loginpage_page">
        <h3>Login Page</h3>
        <label>Username</label>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label>Password</label>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={(e) => handleClick(e)}>LOGIN</button>
      </div>
    </div>
  );
}
