import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Menu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userid = useSelector((state) => state.userid);
  const handleLogout = (_) => {
    dispatch({ type: "setUserid", payload: "" });
    dispatch({ type: "setUsername", payload: "" });
    dispatch({ type: "setPassword", payload: "" });
    navigate("/");
  };
  useEffect((_) => {
    if (userid == "") {
      navigate("/");
    }
  }, []);
  return (
    <>
      {" "}
      <div className="page_menu">
        <ul>
          <li onClick={(e) => navigate("/")}>Home</li>
          <li onClick={(e) => navigate("/Bills")}>Bills</li>
          <li
            onClick={(e) => {
              handleLogout(e);
            }}
          >
            Logout
          </li>
        </ul>
      </div>
    </>
  );
}
